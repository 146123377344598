import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  min-height: 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const MainArea = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  @media (max-width: 768px) {
    order: -1;
  }
`;

export const NoCurrentOrgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const NoCurrentOrgMessage = styled.div`
  max-width: 600px;
  font-size: 1.4rem;
`;

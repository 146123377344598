import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 680px;
  padding: 3rem;

  background-color: ${(p) => p.theme.background.secondary};
  border-radius: 8px;

  @media (max-width: 768px) {
    padding: 2rem;
    margin: 2rem;
  }
`;

export const Description = styled.p`
  margin-top: 1rem;
  text-align: center;
`;

export const ErrorMessage = styled.p`
  margin-top: 1rem;
  text-align: center;
  color: ${(p) => p.theme.color.palette.tomatoRed};
`;

export const MutedText = styled.p`
  margin-bottom: 1rem;
  font-size: 0.8rem;
  color: ${(p) => p.theme.text.muted};
`;

export const UserCodeInput = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0;
`;

export const Separator = styled.span`
  &::before {
    content: "—";
    margin: 0 4px;
  }
`;

// Input styles copied (mostly) from the `TextField` component.
export const CharBox = styled.input`
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  caret-color: transparent;

  @media (max-width: 768px) {
    font-size: 24px;
  }

  width: 3ch;
  height: 3.6ch;
  margin: 0 2px;

  border: 1px solid ${(p) => p.theme.input.default.border};
  border-radius: 4px;

  color: ${(p) => p.theme.input.default.text};
  background-color: ${(p) => p.theme.input.focused.background};

  transition: border 0.1s ease;

  &:focus {
    color: ${(p) => p.theme.input.focused.text};
    border: 1px solid ${(p) => p.theme.input.focused.border};
  }

  &::selection {
    background-color: transparent;
  }

  &:hover:not(:focus) {
    border: 1px solid ${(p) => p.theme.input.hover.border};
  }
`;

import React, { ReactElement, useState } from "react";
import { t } from "@lingui/macro";

import { useCurrentOrg } from "_/data/orgs";
import { H3 } from "_/components/typography";
import { LogoSquare } from "_/components/logo";
import { Button } from "_/components/button";
import { testPermissions } from "_/components/permission-test";
import { useAuthorizeMachine } from "_/data/machines";
import { useQueryParams } from "_/components/router";
import { useLocation } from "_/components/router";

import { machineUrls } from "_/routes";

import * as S from "./styled";
import { UserCodeInput } from "./user-code-input";

export const DeviceConnect = (): ReactElement => {
  const { data: org, isLoading: orgLoading } = useCurrentOrg();
  const authorizeMachine = useAuthorizeMachine();
  const search = useQueryParams();
  const [_location, setLocation] = useLocation();
  const [userCode, setUserCode] = useState("");

  if (orgLoading) {
    return <></>;
  }

  const hasPermission = testPermissions(
    ["machines/write", "apiKeys/create"],
    org.permissions
  );

  const submit = async () => {
    const authGrant = await authorizeMachine.mutateAsync({
      userCode,
      orgId: org.id,
    });

    if (authGrant.authorizedMachine) {
      // Redirect to the machine detail
      const machineUrl = machineUrls.index(authGrant.authorizedMachine);
      setLocation(machineUrl);
    }
  };

  const errorMessage = authorizeMachine.error
    ? t`components.device-connect.error`
    : null;

  const content = hasPermission ? (
    <>
      <H3>{t`components.device-connect.title`}</H3>
      <S.Description>
        {t`components.device-connect.description ${org.name}`}
      </S.Description>
      <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
      <UserCodeInput
        defaultValue={search.code}
        onChange={setUserCode}
        onSubmit={submit}
      />
      <S.MutedText>{t`components.device-connect.code-warning`} </S.MutedText>
      <Button
        onClick={submit}
        disabled={userCode.length < 8}
      >{t`common.continue`}</Button>
    </>
  ) : (
    <>
      <H3>{t`components.device-connect.missing-perms-title`}</H3>
      <S.Description>
        {t`components.device-connect.missing-perms-description ${org.name}`}
      </S.Description>
    </>
  );

  return (
    <S.Wrapper>
      <S.Main>
        <LogoSquare />
        {content}
      </S.Main>
    </S.Wrapper>
  );
};

import React, { useEffect, ReactElement } from "react";
import { useForm } from "react-hook-form";
import { t } from "@lingui/macro";
import { useLocation } from "_/components/router";

import { useQueryParams } from "_/components/router";
import { FormTextField } from "_/components/text-field";
import { Button } from "_/components/button";
import { H3 } from "_/components/typography";
import { useToasts } from "_/components/toasts";

import { User } from "_/data/users";

import { api, ApiResponse } from "_/utils";
import { routeUrls } from "_/routes";

import * as S from "./styled";

export const AccountRsvp = (): ReactElement => {
  const [_location, setLocation] = useLocation();
  const { control, handleSubmit } = useForm({});
  const [_toasts, addToast, _removeToast] = useToasts();
  const queryParams = useQueryParams();

  useEffect(() => {
    if (!queryParams.token || !queryParams.id) {
      addToast({
        kind: "danger",
        title: t`components.account-rsvp.error`,
        content: t`components.account-rsvp.missing-query-params`,
      });

      setLocation(routeUrls.public.login);
    }
  }, [addToast, queryParams, setLocation]);

  const onSubmit = handleSubmit(async (data) => {
    const rsvpData = {
      id: queryParams.id,
      token: queryParams.token,
      password: data.password,
    };

    const resp = await api.post<ApiResponse<User>>("/users/rsvp", rsvpData);
    const user = resp.data.data;

    addToast({
      kind: "success",
      title: t`common.success`,
      content: t`components.account-rsvp.welcome-message ${user.name}`,
      timeout: 5000,
    });

    setLocation(routeUrls.public.login);
  });

  return (
    <S.Wrapper>
      <H3>{t`components.account-rsvp.title`}</H3>
      <S.Form onSubmit={onSubmit}>
        <FormTextField
          control={control}
          name="password"
          rules={{ required: true }}
          label={t`common.password`}
          type="password"
        />
        <FormTextField
          control={control}
          name="password1"
          rules={{
            required: true,
            validate: (value, formValues) =>
              value === formValues.password || t`common.password-mismatch`, // "Passwords do not match",
          }}
          label={t`components.account-rsvp.password-confirmation`}
          type="password"
        />
        <Button type="submit">Continue</Button>
      </S.Form>
    </S.Wrapper>
  );
};

import React, { ReactElement } from "react";
import { useLocation } from "_/components/router";
import { t } from "@lingui/macro";

import { MenuItem, OverlayMenu } from "_/components/overlay-menu";
import { UserAvatar } from "_/components/avatar";
import { SearchBox } from "_/components/search-box";

import { User, useCheckUser, useLogoutUser } from "_/data/users";
import {
  useCheckCurrentOrg,
  useMemberOrgs,
  useCheckOrgs,
  useSetCurrentOrg,
  useHasAdminAccess,
} from "_/data/orgs";

import { Uuid } from "_/types";
import { Select } from "_/components/select";
import { adminUrls, routeUrls, settingsUrls } from "_/routes";

import * as S from "./styled";

const OrgPicker = (): ReactElement => {
  const { data: currentOrg } = useCheckCurrentOrg();
  const { data: orgs } = useMemberOrgs();
  const setCurrentOrg = useSetCurrentOrg();

  if (orgs.length <= 1) {
    return <></>;
  }

  const orgOptions = orgs.map((org) => {
    return {
      label: org.name,
      value: org.id,
    };
  });

  return (
    <S.SelectWrapper>
      <Select
        options={orgOptions}
        value={currentOrg?.id}
        isSearchable={false}
        onChange={(id: Uuid) => {
          setCurrentOrg.mutate({ id });
        }}
      />
    </S.SelectWrapper>
  );
};

/**
 * Dropdown menu for Header component
 */
const Nav = ({ user }: { user: User }): ReactElement => {
  const [_, setLocation] = useLocation();
  const logoutUser = useLogoutUser();
  const hasAdminAccess = useHasAdminAccess();

  const menuItems: (MenuItem | false | undefined)[] = [
    {
      icon: "Person",
      render: t`component.header.settings`,
      onClick: () => setLocation(settingsUrls.general),
    },
    hasAdminAccess && {
      icon: "AdminPanelSettings",
      render: t`component.header.manage`,
      onClick: () => setLocation(adminUrls.index),
    },
    {
      icon: "Logout",
      render: t`component.header.logout`,
      onClick: () => {
        logoutUser.mutate(undefined, {
          onSuccess: () => {
            setLocation(routeUrls.public.login);
          },
        });
      },
    },
  ] as const;
  const filteredMenuItems = menuItems.filter(Boolean);

  const userAvatar = (
    <S.UserInfoContainer>
      <UserAvatar resource={user} />
      <S.UserName>{user.name}</S.UserName>
      <S.DownIcon variant="ArrowDropDown" />
    </S.UserInfoContainer>
  );

  return (
    <>
      <SearchBox />
      <S.Nav>
        <OrgPicker />
        <OverlayMenu
          placement="bottomEnd"
          target={userAvatar}
          items={filteredMenuItems}
        />
      </S.Nav>
    </>
  );
};

export const Header = (): ReactElement => {
  const { data: user } = useCheckUser();
  const { data: orgs } = useCheckOrgs();
  const [_, setLocation] = useLocation();

  function goHome() {
    if (user) {
      setLocation(routeUrls.index);
    } else {
      setLocation(routeUrls.public.login);
    }
  }

  return (
    <S.Wrapper>
      <S.TitleLogo onClick={goHome} />
      {user && orgs && <Nav user={user} />}
    </S.Wrapper>
  );
};

import styled from "styled-components";
import { Icon as _Icon } from "_/components/icon";
import { Link as _Link } from "_/components/router";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 80px;
  padding-top: 16px;

  background-color: ${(p) => p.theme.sidebar.background};

  transition: width 0.1s ease;

  @media (max-width: 768px) {
    flex-direction: row;
    width: 100%;
    height: 80px;
    padding: 0 1rem;
  }
`;

export const TopNav = styled.nav`
  display: flex;
  flex-direction: column;

  margin-bottom: auto;

  @media (max-width: 768px) {
    flex-direction: row;
    margin-bottom: 0;
    margin-right: auto;
  }
`;

export const BottomNav = styled.nav`
  display: flex;
  flex-direction: column;

  margin-top: auto;

  @media (max-width: 768px) {
    flex-direction: row;
    margin-top: 0;
    margin-left: auto;
  }
`;

export const Link = styled(_Link)<{ $active: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5px;
  transition: color 0.2s ease;

  padding: 10px 0;
  margin: 2px 3px 0 0;

  color: ${(p) =>
    p.$active
      ? p.theme.sidebar.item.text.active
      : p.theme.sidebar.item.text.default};

  border-left: 3px solid ${(p) => (p.$active ? "white" : "transparent")};

  cursor: pointer;

  & svg {
    transition: transform 0.2s ease;
  }

  &:hover {
    color: ${(p) => p.theme.sidebar.item.text.hover};
    svg {
      transform: scale(1.1);
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
    padding: 0 10px;
    margin: 0;
    border-left: none;
    border-bottom: 3px solid ${(p) => (p.$active ? "white" : "transparent")};

    svg {
      transform: scale(0.8);
    }

    &:hover {
      svg {
        transform: scale(1);
      }
    }
  }
`;

export const LabelText = styled.span`
  font-size: 0.6875rem;
  font-weight: lighter;
`;

export const Icon = styled(_Icon)`
  font-size: 36px;
`;

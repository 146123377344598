import { inject } from "regexparam";

import { Uuid } from "_/types";

const PROJECT_DETAIL_ROUTE = `/projects/:projectId`;
const MACHINE_DETAIL_ROUTE = `/machines/:machineId`;

/**
 * Routes define all the valid url paths in the application and the patterns
 * matched on. They must be valid path patterns that can be matched on by the
 * `regexparam` library.
 */
export const routes = {
  index: "/",
  connect: "/connect",
  project: {
    prepare: `${PROJECT_DETAIL_ROUTE}/prepare/:revisionId?`,
    optimize: `${PROJECT_DETAIL_ROUTE}/optimize/:simulationId?`,
    analyze: `${PROJECT_DETAIL_ROUTE}/analyze/:attemptId?`,
    history: `${PROJECT_DETAIL_ROUTE}/history`,
    wildcards: {
      index: `${PROJECT_DETAIL_ROUTE}/*?`,
      prepare: `${PROJECT_DETAIL_ROUTE}/prepare/*?`,
      optimize: `${PROJECT_DETAIL_ROUTE}/optimize/*?`,
      analyze: `${PROJECT_DETAIL_ROUTE}/analyze/*?`,
      combinedViewer: `${PROJECT_DETAIL_ROUTE}/(prepare|analyze|optimize)/*?`,
    },
  },
  projects: {
    index: "/projects",
    wildcards: {
      index: "/projects/*?",
    },
  },
  machine: {
    index: MACHINE_DETAIL_ROUTE,
    thermals: `${MACHINE_DETAIL_ROUTE}/thermals`,
    events: `${MACHINE_DETAIL_ROUTE}/events`,
    configuration: `${MACHINE_DETAIL_ROUTE}/configuration`,
    wildcards: {
      index: `${MACHINE_DETAIL_ROUTE}/*?`,
    },
  },
  machines: {
    index: "/machines",
    wildcards: {
      index: "/machines/*?",
    },
  },
  settings: {
    index: "/settings",
    general: "/settings/general",
    organizations: "/settings/organizations",
    organization: "/settings/organizations/:id",
    security: "/settings/security",
    wildcards: {
      index: "/settings/*?",
    },
  },
  admin: {
    index: "/admin",
    organizations: "/admin/organizations",
    organization: "/admin/organizations/:id",
    machines: "/admin/machines",
    search: "/admin/search",
    materials: "/admin/materials",
    content: "/admin/content",
    wildcards: {
      index: "/admin/*?",
      organizations: "/admin/organizations/*?",
    },
  },
  public: {
    headless: "/_headless_",
    login: "/login",
    verify: "/verify",
    recovery: "/recovery",
    rsvp: "/rsvp",
  },
  docs: {
    index: "/docs",
    page: "/docs/:slug",
    wildcards: {
      // A manually defined regex is required to match on slugs containing
      // "/" characters, as these are not matched by the default route parser.
      index: /^\/docs(?:\/(?<slug>[^/].*)?)?/,
    },
  },
} as const;

export const adminRoutes = routes.admin;
export const docsRoutes = routes.docs;
export const machineRoutes = routes.machine;
export const projectRoutes = routes.project;
export const publicRoutes = routes.public;
export const settingsRoutes = routes.settings;

/**
 * Route URLs are the functions and static URLs that should be used when constructing links
 * and navigating within the application. In essence, these are the concrete forms of routes.
 */
export const routeUrls = {
  index: routes.index,
  connect: routes.connect,
  project: {
    index: (projectId: Uuid) => inject(routes.project.prepare, { projectId }),
    prepare: (projectId: Uuid, revisionId?: Uuid) =>
      inject(routes.project.prepare, { projectId, revisionId }),
    optimize: (projectId: Uuid, simulationId?: Uuid) =>
      inject(routes.project.optimize, { projectId, simulationId }),
    analyze: (projectId: Uuid, attemptId?: Uuid) =>
      inject(routes.project.analyze, { projectId, attemptId }),
    history: (projectId: Uuid) => inject(routes.project.history, { projectId }),
  },
  projects: {
    index: routes.projects.index,
  },
  machine: {
    index: (machineId: Uuid) => inject(routes.machine.index, { machineId }),
    thermals: (machineId: Uuid) =>
      inject(routes.machine.thermals, { machineId }),
    events: (machineId: Uuid) => inject(routes.machine.events, { machineId }),
    configuration: (machineId: Uuid) =>
      inject(routes.machine.configuration, { machineId }),
  },
  machines: {
    index: routes.machines.index,
  },
  settings: {
    index: routes.settings.index,
    general: routes.settings.general,
    security: routes.settings.security,
    organizations: routes.settings.organizations,
    organization: (id: Uuid) => inject(routes.settings.organization, { id }),
  },
  admin: {
    index: routes.admin.index,
    organizations: routes.admin.organizations,
    organization: (id: Uuid) => inject(routes.admin.organization, { id }),
    machines: routes.admin.machines,
    materials: routes.admin.materials,
    search: routes.admin.search,
    content: routes.admin.content,
  },
  docs: {
    index: routes.docs.index,
    page: (slug: string) => inject(routes.docs.page, { slug }),
  },
  public: routes.public,
};

export const projectUrls = routeUrls.project;
export const machineUrls = routeUrls.machine;
export const settingsUrls = routeUrls.settings;
export const adminUrls = routeUrls.admin;
export const publicUrls = routeUrls.public;
export const docsUrls = routeUrls.docs;

export const isPublicUrl = (pathname: string) => {
  return Object.values(publicUrls).some(
    (route) => route === pathname.toLowerCase()
  );
};

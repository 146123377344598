import { ReactNode } from "react";
import { useCurrentUserPermissions } from "_/data/rbac";
import { useCurrentOrg } from "_/data/orgs";

import type { Permission } from "_/data/rbac";

/**
 * Function to compare the permissions assigned to a user against the permissions
 * required to access a resource.
 */
export function testPermissions(
  /**
   * The permissions required to access a resource. If multiple arrays of permissions
   * are provided, the user must have all permissions in at least one array.
   */
  requiredPermissions: Permission | Permission[] | Permission[][],
  /**
   * The permissions assigned to the user.
   */
  assignedPermissions: Permission[]
): boolean {
  // Format the permissions into an array of arrays if not already in that format.
  // This allows for multiple arrays of permissions to be provided, where the user
  // must have all permissions in at least one array.
  const formattedRequiredPermissions = (
    Array.isArray(requiredPermissions)
      ? requiredPermissions.every(Array.isArray)
        ? requiredPermissions
        : [requiredPermissions]
      : [[requiredPermissions]]
  ) as Permission[][];

  const hasRequiredPermissions = formattedRequiredPermissions.some((perms) =>
    perms.every((perm) => assignedPermissions?.includes(perm))
  );

  return hasRequiredPermissions;
}

type PermissionTestRenderProp = (props: { allowed: boolean }) => ReactNode;

type PermissionTestProps =
  | ({
      /**
       * If multiple arrays of permissions are provided, the user must have all permissions in at least one array.
       */
      requiredPermissions: Permission | Permission[] | Permission[][];
      domainId?: string;
      fallback?: ReactNode;
    } /**
     * Either `children` or `render`, but not both, must be provided.
     */ & (
      | { children?: ReactNode; render?: undefined }
      | { children?: undefined; render?: PermissionTestRenderProp }
    ))
  | {
      /**
       * If `permissions` is not provided, the component will always render its children.
       * In this case, `domainId`, `fallback`, and `render` are not allowed.
       */
      requiredPermissions: undefined;
      domainId: undefined;
      fallback: undefined;
      render: undefined;
      children: ReactNode;
    };

export const PermissionTest = ({
  requiredPermissions,
  domainId,
  fallback,
  children,
  render,
}: PermissionTestProps): ReactNode => {
  const { data: org } = useCurrentOrg();

  const { data: assignedPermissions } = useCurrentUserPermissions(
    domainId ?? org.domainId
  );

  const hasRequiredPermissions =
    !requiredPermissions ||
    testPermissions(requiredPermissions, assignedPermissions);

  if (render) {
    return render({ allowed: hasRequiredPermissions });
  }

  if (!hasRequiredPermissions) {
    return fallback ?? null;
  }

  return children;
};
